import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

export const firebaseConfig = {
  apiKey: "AIzaSyDUUxNpZtZZkJPPtvlDNi422IjpkfbXZ_w",
  authDomain: "uikit-landing-page.firebaseapp.com",
  projectId: "uikit-landing-page",
  storageBucket: "uikit-landing-page.firebasestorage.app",
  messagingSenderId: "633062274617",
  appId: "1:633062274617:web:5cb69084e7c407c232bfe8",
  measurementId: "G-5X404F6XYG",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);
export const db = getFirestore(app);

import { collection, getDocs } from "firebase/firestore";
import { analytics, db } from "./Config";
import { logEvent } from "firebase/analytics";

// Generic function to fetch data from a Firestore collection
const fetchData = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, "ui_data"));
    const data = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    return data;
  } catch (error) {
    console.error("Error fetching data: ", error);
    return [];
  }
};

const logPageView = (pageName) => {
  logEvent(analytics, "page_view", { page: pageName });
  console.log(`Page view: ${pageName}`);
};

const logCustomEvent = (eventName, eventParams) => {
  logEvent(analytics, eventName, eventParams);
  console.log(`Custom event logged: ${eventName}`, eventParams);
};

export { fetchData, logPageView, logCustomEvent };

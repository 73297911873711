import { logCustomEvent } from "../../utils/firebaseHelper";

const Card = ({ itm }) => {
  const handleClick = async () => {
    logCustomEvent("button_click", { id: itm.id, item_typs: itm.item_typs });
    window.open(itm.link, "_blank");

    const callback = () => {
      // window.location = url;
      window.open(itm.link, "_blank");
    };

    window.gtag("event", "conversion", {
      send_to: "AW-11248518308/WGbVCLCx7_IZEKSJ2_Mp",
      value: 1.0,
      currency: "INR",
      id: itm.id,
      item_typs: itm.item_typs,
      event_callback: callback,
    });
  };

  return (
    <div className="bg-white p-6 md:p-8 rounded-lg shadow-lg text-center flex flex-col justify-between">
      <div>
        <div className="flex items-center justify-center">
          <img src={itm.image} alt="Card Logo" className="max-w-[200px] md:max-w-[250px] lg:max-w-[295px] h-auto" />
        </div>
        <div className="text-lg md:text-xl lg:text-2xl text-black font-semibold mt-4 md:mt-6 line-clamp-2">
          {itm.title}
        </div>
        <div className="text-sm md:text-base lg:text-lg text-gray-500 font-normal mt-2 md:mt-4 line-clamp-4">
          {itm.description}
        </div>
      </div>

      <div className="flex items-center justify-between mt-6">
        <div className="flex items-center">
          <div className="bg-gradient-to-r from-[#1A64BE] to-[#293a78] py-2 px-3 font-bold text-white rounded-l-md ">
            ${itm.price ?? 59}
          </div>
          <div className="bg-[#2e3c71] w-0 h-0 border-solid border-y-[20px] border-y-transparent border-r-[20px] border-r-white"></div>
        </div>

        <button
          className="text-sm md:text-base font-bold bg-blue-50 px-4 py-3 rounded-md hover:bg-blue-100 transition"
          onClick={handleClick}
        >
          Buy Now
        </button>
      </div>
    </div>
  );
};

export default Card;

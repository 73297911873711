export const uidataList = [
  {
    id: "1A4RYCogYO13FIo0QRNS",
    image: "https://public-files.gumroad.com/qcsupe2s2r84se8qclg41ssyrq7r",
    title: "Hotify - Music Streaming App",
    description:
      "Hotify is a revolutionary music streaming app built on the React Native CLI UI Kit, designed to deliver an exceptional listening experience like no other. With its sleek and intuitive interface, Hotify redefines how you enjoy music, offering a world of possibilities at your fingertips.",
    link: "https://jsmtemplate.gumroad.com/l/hotify_expo",
    item_typs: "hotify",
  },
  {
    id: "5gIgWuJsiRNSmexrR7Hf",
    description:
      "Playzone is online gaming app template in react native. So, It is support android and ios both. In this app user can play various type of games which is available in this app. User can also see the livestream of the game. User can also see the streamer profile. etc.",
    link: "https://jsmtemplate.gumroad.com/l/playzone_expo",
    title: "Playzone - Game Application",
    item_typs: "playzone",
    image: "https://public-files.gumroad.com/nmdqjd5gt3mqxhosq8b72d9k08ei",
  },
  {
    id: "69fBDNEUeKQq0WQfH6Fr",
    title: "Ecomark - E-commerce Application",
    item_typs: "ecomark",
    description:
      "Ecomark is a powerful and versatile E-commerce React Native Expo Ui Kit designed to help developers and business owners create stunning and functional online stores. Built using the latest technologies and best practices, Ecomark provides a comprehensive solution for building e-commerce applications that are fast, reliable, and easy to use.",
    link: "https://jsmtemplate.gumroad.com/l/ecomark_expo",
    image: "https://public-files.gumroad.com/fc7cvzw4h0npj3ad0gmkslshmlbq",
  },
  {
    id: "9qlLw8iblHJ2HYiQFYrZ",
    image: "https://public-files.gumroad.com/hz07abd812btkub2vp1gqkxuik6b",
    title: "Grocery - E Grocery Application",
    item_typs: "grocery",
    link: "https://jsmtemplate.gumroad.com/l/grocery_expo",
    description:
      "Grocery App React Native CLI Ui Kit is a comprehensive and feature-rich product designed to transform the grocery shopping experience for both users and developers. Built on the powerful React Native CLI framework, this UI kit provides an all-in-one solution for creating modern and user-friendly grocery shopping applications on both Android and iOS platforms.",
  },
  {
    id: "DlSE0jznA3jPWC7BK6Wg",
    link: "https://jsmtemplate.gumroad.com/l/furnia_expo",
    title: "Furnia - Furniture ECommerce Application",
    description:
      "Introducing Furnia – the revolutionary Furniture E-Commerce App React Native CLI UI Kit that will transform the way you build furniture online shopping experiences. Furnia is a comprehensive and feature-packed UI Kit, specially crafted for React Native, designed to streamline the development process for furniture e-commerce applications. With Furnia, you can create stunning, fully functional, and responsive apps that cater to the unique needs of furniture retailers and customers alike.",
    item_typs: "Furnia",
    image: "https://public-files.gumroad.com/za0yeqga9k3u56e8dgw4asyaxpvp",
  },
  {
    id: "GE2JrtrLYYjOedzMQpkr",
    link: "https://jsmtemplate.gumroad.com/l/finvest_expo",
    image: "https://public-files.gumroad.com/51fhrrt3b1g998u4kksjpk0qcei0",
    title: "Finvest - Investments & Finance Application",
    item_typs: "finvest",
    description:
      "Investments & Finance App React Native CLI UI Kit is a robust and versatile solution designed to streamline the development of finance and investment applications. Tailored for developers aiming to create top-tier financial apps, this UI kit offers a comprehensive suite of components and tools. With a focus on modern design principles, Finvest ensures a seamless user experience that is both intuitive and visually appealing. Its pre-built components and templates cater to various financial needs, including portfolio management, stock tracking, budgeting, and more, making it a go-to resource for finance app developers.",
  },
  {
    id: "HoW5mmb5xoCL7gtNrVxt",
    image: "https://public-files.gumroad.com/xruiykopyqy6l6ku04rptbrani6n",
    item_typs: "evano",
    link: "https://jsmtemplate.gumroad.com/l/evano_expo",
    title: "Evano - Event Booking Application",
    description:
      "Event Booking React Native CLI Ui Kit is a comprehensive user interface (UI) toolkit designed specifically for developers who want to create event booking mobile applications using React Native CLI. The toolkit includes a set of pre-designed screens and UI components that can be easily customized and integrated into your project.",
  },
  {
    id: "LZsIoEEdIRjw8ogJKfmi",
    image: "https://public-files.gumroad.com/fc7cvzw4h0npj3ad0gmkslshmlbq",
    description:
      "Shopia is your go-to e-commerce app for all things sneakers and shoes, brought to you with the power of React Native CLI UI Kit. With a sleek and user-friendly interface, shopping for the latest kicks has never been easier. Whether you’re a sneakerhead looking for limited-edition releases or simply in search of comfortable and stylish footwear, Shopia has you covered.",
    title: "Shopia - Sneakers & Shoes E-Commerce Application",
    item_typs: "shopia",
    link: "https://jsmtemplate.gumroad.com/l/shopia_expo",
  },
  {
    id: "MIoVP19bOZqGSSO9HDd6",
    title: "Invest - Stock Market App",
    item_typs: "invest",
    description:
      "Stock Market App React Native CLI Ui Kit is a versatile and powerful tool designed to streamline the development of stock market and investment-related mobile applications. With its comprehensive set of UI components and React Native CLI integration, this kit empowers developers to create sleek and functional stock trading apps with ease.",
    link: "https://jsmtemplate.gumroad.com/l/invest_expo",
    image: "https://public-files.gumroad.com/pg2gthhhzdj6qxbl80rgsfq3f66t",
  },
  {
    id: "OntrbA6JJfR3dKUNiNgK",
    description:
      "Matchly is a cutting-edge dating app React Native Expo UI Kit designed to  provide a seamless and engaging experience for users looking for meaningful  connections. With its sleek and intuitive interface, Matchly offers a modern  and visually appealing design that captivates users from the moment they  launch the app. The UI Kit is built using React Native, ensuring  cross-platform compatibility and smooth performance on both iOS and Android  devices.",
    image: "https://public-files.gumroad.com/xwfjzg9lxi2gez4m4gxmfwlymglp",
    item_typs: "matchly",
    link: "https://jsmtemplate.gumroad.com/l/matchly",
    title: "Matchly- Dating Application",
  },
  {
    id: "VA88SHzhDcsUEEjzdqcb",
    title: "Carflix - Car Marketplace Application",
    description:
      "Carflix is a cutting-edge Car Marketplace React Native CLI Ui Kit designed to provide users with a seamless and intuitive car buying experience. With its sleek and modern interface, Carflix offers a comprehensive solution for both buyers and sellers in the automotive industry. The UI kit is developed using React Native, ensuring cross-platform compatibility and smooth performance on both iOS and Android devices.",
    item_typs: "carflix",
    image: "https://public-files.gumroad.com/v4639zknlqr7vtfxw52k2cwl7k8t",
    link: "https://jsmtemplate.gumroad.com/l/carflix_expo",
  },
  {
    id: "XOzTnlexfJ09iNxMgix4",
    item_typs: "anistream",
    link: "https://jsmtemplate.gumroad.com/l/anistream_expo",
    title: "Anistream - Streaming Application",
    description:
      "Anistream is online streaming app template in react native. So, It is support android and ios both. In this app user can watch various types of Movies, Shows and series. In addition to its extensive collection of movies and TV shows, our app also offers a range of features that enhance your viewing experience. These include personalized recommendations, the ability to create and share playlists, and support for multiple languages etc.",
    image: "https://public-files.gumroad.com/eshifkz638zlwogqgjdfpnhwo3lt",
  },
  {
    id: "cWGZi0tchLOIhFMO76tC",
    item_typs: "instagram",
    title: "Instagram - Social Media Application",
    link: "https://jsmtemplate.gumroad.com/l/instagram_expo",
    image: "https://public-files.gumroad.com/anljdnfnf0ejacnhay5rjll9u835",
    description:
      "Social Media React Native CLI UI Kit is a cutting-edge toolkit designed to streamline the development process of social media applications. With its intuitive and responsive user interface, this UI kit allows developers to create stunning mobile apps that emulate the popular Instagram platform. Powered by React Native CLI, the kit offers seamless integration and compatibility with existing React Native projects, making it a versatile choice for both new and experienced developers.",
  },
  {
    id: "fFfV8KSK5meH3tyAUFtL",
    link: "https://jsmtemplate.gumroad.com/l/academy_expo",
    image: "https://public-files.gumroad.com/vnqzakscqoxdkppzh06w3a6wxzog",
    item_typs: "academy",
    description:
      "The Academy Online E-Learning App is a game-changer, developed using React Native CLI and featuring a state-of-the-art UI kit. This powerful combination ensures a seamless and engaging learning experience. Whether you’re a student, professional, or lifelong learner, the Academy app is designed to bring education to your fingertips, wherever you are.",
    title: "Academy - Online E-Learning Application",
  },
  {
    id: "gT1WLI40XzYOaZCUcou8",
    image: "https://public-files.gumroad.com/xadytnet698ezvnaun149c5carda",
    description:
      "The most exciting and dynamic social media app for short videos! Designed using the latest React Native technology, Tikto is fully optimized for both Android and iOS devices, giving you an unforgettable user experience.",
    link: "https://jsmtemplate.gumroad.com/l/tikto_expo",
    item_typs: "tikto",
    title: "Tikto - Short Video Social App",
  },
  {
    id: "jLenwFe6IKyDM0AMf5CV",
    title: "Barber - Salon Booking Application",
    link: "https://jsmtemplate.gumroad.com/l/barber_expo",
    description:
      "The Barber-Salon Booking React Native CLI UI Kit is a comprehensive collection of pre-designed user interface components specifically tailored for developing barber and salon booking applications using React Native. It offers a seamless and delightful user experience for customers and service providers in the grooming industry. Built on React Native, it works smoothly on both iOS and Android devices.",
    image: "https://public-files.gumroad.com/9m3t0ysqihamo23quuydbabq6jb7",
    item_typs: "barber",
  },
  {
    id: "lJdrjvCGMTDma7cE47kB",
    link: "https://jsmtemplate.gumroad.com/l/helpify_expo",
    description:
      "Helpify is a cutting-edge home service app built using React Native CLI Ui Kit, designed to simplify and enhance the way users request and avail various home services. With its intuitive user interface and powerful functionality, Helpify brings convenience right to the fingertips of homeowners. The app offers a wide range of services, including plumbing, electrical work, painting, cleaning, and much more, making it a one-stop solution for all home maintenance needs.",
    item_typs: "helpify",
    title: "Helpify- Home Service Application",
    image: "https://public-files.gumroad.com/dainoagtrpvjxiaej6yvonrsyucv",
  },
];
